<template>
  <b-form @submit.prevent>
    <b-tabs>
      <b-tab title="Основные данные">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Название:"
              label-for="name"
            >
              <ValidationProvider
                #default="{ errors }"
                name="name"
                vid="name"
              >
                <b-form-input
                  id="name"
                  ref="clinicData.name"
                  v-model="clinicData.name"
                  autocomplete="off"
                  placeholder="Введите название компании"
                />
                <div class="d-flex justify-content-between align-items-baseline">
                  <fast-insert
                    :input-ref="$refs['clinicData.name']"
                    :suggestions="['«»']"
                    input-ref-name="clinicData.name"
                    @fastInsertSelected="onSelectedFastInsertSuggestion"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="ЧПУ URL:"
              label-for="slug"
            >
              <ValidationProvider
                #default="{ errors }"
                name="slug"
                vid="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="clinicData.slug"
                  autocomplete="off"
                  placeholder="Введите ЧПУ URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Город:"
              label-for="city_id"
            >
              <ValidationProvider
                #default="{ errors }"
                name="city_id"
                vid="city_id"
              >
                <v-select
                  v-model="clinicData.city_id"
                  label="name"
                  :reduce="option => option.id"
                  :options="citiesList"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Сеть:"
              label-for="network_id"
            >
              <ValidationProvider
                #default="{ errors }"
                name="network_id"
                vid="network_id"
              >
                <v-select
                  v-model="clinicData.network_id"
                  label="name"
                  :reduce="option => option.id"
                  :options="[{id: '', name: '------------'}, ...clinicNetworksList]"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Адрес без города:"
              label-for="address"
            >
              <ValidationProvider
                #default="{ errors }"
                name="address"
                vid="address"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.address"
                  autocomplete="off"
                  placeholder="ул. Советская, д. 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Телефон:"
              label-for="phone"
            >
              <ValidationProvider
                #default="{ errors }"
                name="phone"
                vid="phone"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.phone"
                  autocomplete="off"
                  placeholder="Введите телефон"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Широта:"
              label-for="latitude"
            >
              <ValidationProvider
                #default="{ errors }"
                name="latitude"
                vid="latitude"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.latitude"
                  autocomplete="off"
                  placeholder="55.4335"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Долгота:"
              label-for="longitude"
            >
              <ValidationProvider
                #default="{ errors }"
                name="longitude"
                vid="longitude"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.longitude"
                  autocomplete="off"
                  placeholder="37.3535"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Описание как добратся до клиники:"
              label-for="transit_help"
            >
              <ValidationProvider
                #default="{ errors }"
                name="transit_help"
                vid="transit_help"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.transit_help"
                  autocomplete="off"
                  placeholder="Введите Описание как добратся до клиники"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Сайт клиники:"
              label-for="web_site_url"
            >
              <ValidationProvider
                #default="{ errors }"
                name="web_site_url"
                vid="web_site_url"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.web_site_url"
                  autocomplete="off"
                  placeholder="Введите URL site"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Партнерский сайт клиники:"
              label-for="ad_site_url"
            >
              <ValidationProvider
                #default="{ errors }"
                name="ad_site_url"
                vid="ad_site_url"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.ad_site_url"
                  autocomplete="off"
                  placeholder="Введите URL site"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Описание:"
              label-for="description"
            >
              <ValidationProvider
                #default="{ errors }"
                name="description"
                vid="description"
              >
                <b-form-textarea
                  id="description"
                  ref="clinicData.description"
                  v-model="clinicData.description"
                  placeholder="Введите описание"
                  rows="3"
                />
                <div class="d-flex justify-content-between align-items-baseline">
                  <fast-insert
                    :input-ref="$refs['clinicData.description']"
                    :suggestions="['«»']"
                    input-ref-name="clinicData.description"
                    @fastInsertSelected="onSelectedFastInsertSuggestion"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Дополнительное описание(условия в клинике при стационаре) клиники:"
              label-for="additional_description"
            >
              <ValidationProvider
                #default="{ errors }"
                name="additional_description"
                vid="additional_description"
              >
                <b-form-textarea
                  id="description"
                  v-model="clinicData.additional_description"
                  placeholder="Введите дополнительное описание (условия в клинике при стационаре) клиники"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="График работы:"
              label-for="schedule"
            >
              <ValidationProvider
                #default="{ errors }"
                name="schedule"
                vid="schedule"
              >
                <b-form-input
                  id="name"
                  ref="clinicData.schedule"
                  v-model="clinicData.schedule"
                  autocomplete="off"
                  placeholder="Введите График работы"
                />
                <div class="d-flex justify-content-between align-items-baseline">
                  <fast-insert
                    :input-ref="$refs['clinicData.schedule']"
                    :suggestions="['Пн-Пт', 'Пн-Сб', 'Пн-Вс', 'Сб-Вс', 'Круглосуточно', '<sup></sup> - <sup></sup>']"
                    input-ref-name="clinicData.schedule"
                    @fastInsertSelected="onSelectedFastInsertSuggestion"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Официальное название:"
              label-for="official_name"
            >
              <ValidationProvider
                #default="{ errors }"
                name="official_name"
                vid="official_name"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.official_name"
                  autocomplete="off"
                  placeholder="Введите Официальное название"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="ФИО директора:"
              label-for="director"
            >
              <ValidationProvider
                #default="{ errors }"
                name="director"
                vid="director"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.director"
                  autocomplete="off"
                  placeholder="Введите ФИО директора"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Год открытия:"
              label-for="opening_year"
            >
              <ValidationProvider
                #default="{ errors }"
                name="opening_year"
                vid="opening_year"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.opening_year"
                  autocomplete="off"
                  placeholder="Введите год открытия"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Способ оплаты:"
              label-for="payment_method"
            >
              <ValidationProvider
                #default="{ errors }"
                name="payment_method"
                vid="payment_method"
              >
                <b-form-input
                  id="name"
                  ref="clinicData.payment_method"
                  v-model="clinicData.payment_method"
                  autocomplete="off"
                  placeholder="Введите способ оплаты"
                />
                <div class="d-flex justify-content-between align-items-baseline">
                  <fast-insert
                    :input-ref="$refs['clinicData.payment_method']"
                    :suggestions="['Наличный', 'Безналичный', 'ДМС', 'ОМС']"
                    input-ref-name="clinicData.payment_method"
                    @fastInsertSelected="onSelectedFastInsertSuggestion"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Типы клиентов:"
              label-for="client_types"
            >
              <ValidationProvider
                #default="{ errors }"
                name="client_types"
                vid="client_types"
              >
                <b-form-input
                  id="name"
                  ref="clinicData.client_types"
                  v-model="clinicData.client_types"
                  autocomplete="off"
                  placeholder="Введите Типы клиентов"
                />
                <div class="d-flex justify-content-between align-items-baseline">
                  <fast-insert
                    :input-ref="$refs['clinicData.client_types']"
                    :suggestions="['Взрослым', 'Детям']"
                    input-ref-name="clinicData.client_types"
                    @fastInsertSelected="onSelectedFastInsertSuggestion"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Рейтинг персонала:"
              label-for="rating_personal"
            >
              <ValidationProvider
                #default="{ errors }"
                name="rating_personal"
                vid="rating_personal"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.rating_personal"
                  autocomplete="off"
                  placeholder="Введите Рейтинг персонала"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Рейтинг оснащенности:"
              label-for="rating_equipment"
            >
              <ValidationProvider
                #default="{ errors }"
                name="rating_equipment"
                vid="rating_equipment"
              >
                <b-form-input
                  id="name"
                  v-model="clinicData.rating_equipment"
                  autocomplete="off"
                  placeholder="Введите Рейтинг оснащенности"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Тип клиники:"
              label-for="type_id"
            >
              <ValidationProvider
                #default="{ errors }"
                name="type_id"
                vid="type_id"
              >
                <v-select
                  v-model="clinicData.type_id"
                  label="name"
                  :reduce="option => option.id"
                  :options="[{'id': '', 'name': '------------'}, ...clinicTypesList]"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Государственная или частная:"
              label-for="state"
            >
              <ValidationProvider
                #default="{ errors }"
                name="state"
                vid="state"
              >
                <b-form-select
                  id="state"
                  v-model="clinicData.state"
                  value-field="id"
                  text-field="value"
                  :options="[{id: 3, value: 'Не выбрано'}, {id: 0, value: 'Государственная'}, {id: 1, value: 'Частные'}]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

        </b-row>
      </b-tab>
      <b-tab title="Врачи">
        <list-doctors
          :clinic-city-id="clinicData.city_id"
        />
      </b-tab>
    </b-tabs>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmitClose"
        >
          {{ clinicData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div v-if="clinicData.id">
        <b-button
          variant="primary"
          class="mr-1"
          @click="formSubmitReload"
        >
          Обновить
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
  BFormTextarea,
  BTabs,
  BTab, BFormSelect,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ListDoctors from './doctors/List.vue'

export default {
  name: 'ClinicsForm',
  components: {
    BFormSelect,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BTabs,
    BTab,
    ListDoctors,
  },
  props: {
    clinicData: {
      type: Object,
      require: true,
      default: () => {},
    },
    citiesList: {
      type: Array,
      require: true,
      default: () => [],
    },
    clinicNetworksList: {
      type: Array,
      require: true,
      default: () => [],
    },
    clinicTypesList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    formSubmitClose() {
      this.$emit('formSubmitClose', {
        reload: true,
      })
    },
    formSubmitReload() {
      this.$emit('formSubmitReload', {
        reload: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
